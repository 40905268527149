.icon-small {
    background-image: url("lightyear-narrow.svg");
    background-repeat: no-repeat;
    background-size: contain;
    height: 48px;
    margin-bottom: 1em;
    margin-top: 1em;
    width: 24px;
}

.icon-full {
    background-image: url("lightyear-full.svg");
    background-repeat: no-repeat;
    background-size: contain;
    height: 73px;
    margin-bottom: 1em;
    margin-top: 1em;
    width: 180px;
}